import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppSelector, type RootState, type AppDispatch } from '@/lib/redux';

import Table from '@/components/common/tables/Table';
import WidgetBox from '../../widget-box/WidgetBox';
import { getTrustedDevice } from '@/lib/redux/slices/trusted-device/actions';
import { toUtcDateString, getValidDate } from '@/utils/dateTimeUtils';
import Spinner from '@/components/common/spinners/Spinner';
import { type TrustedDevice } from '@/types/TrustedDevice';
import StatusStamp from '@/components/common/status-stamp/StatusStamp';
import SomethingWentWrong from '@/components/common/something-went-wrong/SomethingWentWrong';

const TrustedDeviceWidget = (): JSX.Element => {
  const { t } = useTranslation('customerOverview');
  const dispatch = useDispatch<AppDispatch>();

  const { customer } = useAppSelector((state: RootState) => state.customer);
  const { loading, isError, trustedDevice } = useAppSelector((state: RootState) => state.trustedDevice);
  const latestDevice: TrustedDevice | undefined =
    trustedDevice && trustedDevice.length > 0 ? trustedDevice[0] : undefined;
  const customerId = customer?.customerId;
  const defaultStringValue = 'N/A';

  useEffect(() => {
    if (customerId) {
      void dispatch(getTrustedDevice({ customerId }));
    }
  }, [customerId, dispatch]);

  const renderDateField = useCallback((validTrustedDeviceDate: string | undefined): string | undefined => {
    if (validTrustedDeviceDate) {
      return toUtcDateString(getValidDate(validTrustedDeviceDate));
    }

    return defaultStringValue;
  }, []);

  return (
    <WidgetBox
      title={t('widgets.trusted-device.title')}
      label={latestDevice || isError ? undefined : t('widgets.trusted-device.no-certificates')}
      labelVariant='p3'
    >
      {isError && <SomethingWentWrong />}
      {loading && <Spinner />}
      {!loading && !isError && (
        <Table cols={1}>
          <Table.Row>
            <Table.Cell>
              {t('widgets.trusted-device.device-id')}
              {latestDevice?.deviceId ?? defaultStringValue}
              {latestDevice && (
                <div>
                  <StatusStamp
                    status={
                      latestDevice?.isRevoked
                        ? t('widgets.trusted-device.deleted')
                        : t('widgets.trusted-device.trusted')
                    }
                    variant={latestDevice?.isRevoked ? 'errorDark' : 'successDark'}
                  />
                </div>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('widgets.trusted-device.expire-on')}
              {renderDateField(latestDevice?.expirationTimeStamp)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('widgets.trusted-device.deleted-on')}
              {renderDateField(latestDevice?.revocationTimeStamp)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              {t('widgets.trusted-device.reason')}
              {latestDevice?.revocationReason || defaultStringValue}
            </Table.Cell>
          </Table.Row>
        </Table>
      )}
    </WidgetBox>
  );
};

export default TrustedDeviceWidget;
